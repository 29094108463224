<template>
  <div>
    <datatable
      ref="investors"
      :serviceUrl="serviceUrl"
      :customFields="fields"
      :exportToXLSEndpoint="exportToXLSEndpoint"
      :customParams="customParams"
      :pagination="true"
    >
      <template #table-header>
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">Usuários investidores cadastrados na plataforma</h4>
          <div class="d-flex justify-content-end align-items-center">
            <b-input-group class="input-group-merge mr-2 mw-173">
              <b-form-input placeholder="Buscar" v-model="search" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="outline-primary"
              class="mr-2 text-nowrap"
              @click="$router.push({ name: 'admin.investor.invites' })"
            >
              Gestão de convites
            </b-button>
            <b-button
              variant="primary"
              class="mr-2 text-nowrap"
              @click="$router.push({ name: 'admin.investor.invites.new' })"
            >
              Convidar usuário
            </b-button>
          </div>
        </div>
      </template>

      <template
        #table-footer="{ items, excelExport, showing, currentPage, activePagination, totalRows }"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row justify-content-end align-items-center">
              <label class="mb-0 mr-1">Mostrar</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" />
              <label class="mb-0 mr-2 ml-1">registros</label>
            </div>
            <span v-show="items.length" class="mb-0 h5 mx-2"> {{ showing }} </span>
            <b-button
              variant="flat"
              class="mr-2 export-excel-button"
              @click="excelExport"
              :disabled="!items.length"
            >
              Exportar para Excel
              <img
                class="img-icon"
                height="17"
                width="16"
                src="@/assets/images/table-export-orange.svg"
              />
            </b-button>
          </div>
        </div>
      </template>
    </datatable>
  </div>
</template>

<script>
import { BButton, BInputGroup, BInputGroupAppend, BFormInput, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datatable from '@/views/common/crud/components/Datatable.vue'

export default {
  name: 'AdminInvestorIndexPage',
  components: {
    vSelect,
    BButton,
    Datatable,
    BFormInput,
    BInputGroup,
    BPagination,
    BInputGroupAppend,
  },
  data() {
    return {
      search: '',
      perPage: 10,
      perPageOptions: [10, 25, 50],
      serviceUrl: '/admin/usuarios?type=Investidor::User',
      exportToXLSEndpoint: 'admin/usuarios/exportar?type=Investidor::User&',
      fields: [
        {
          label: 'Nome',
          key: 'nome',
        },
        {
          label: 'Celular',
          key: 'telefone',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Acesso ao portal?',
          key: 'portal_investidor',
          formatter: (value) => { return value ? 'Sim' : 'Não' }
        },
        {
          label: '',
          key: 'adminInvestorActions',
        },
      ],
    }
  },
  computed: {
    customParams() {
      return {
        fulltext: this.search,
        per_page: this.perPage,
      }
    },
  },
}
</script>

<style lang="scss">
.mw-173 {
  max-width: 173px;
}
</style>
